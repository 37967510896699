"use client";

import { clarityScript, mixpanelScript } from "@lib/analytics-tracking";
import { sendGTMEvent } from "@next/third-parties/google";
import { getCookie, hasCookie, setCookie } from "cookies-next/client";
import { isAfter, parseISO } from "date-fns";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import Script from "next/script";
import * as React from "react";

import { cookieAge } from "@/lib/hooks/auth";
import { useClarity } from "@/lib/hooks/use-clarity";

const SUBSCRIPTION_TYPE_MAP: Record<string, string> = {
	month: "Monthly",
	year: "Yearly",
	day: "Lifetime",
} as const;

export default function EntryPoint({ subscription, user }) {
	useClarity(user);
	const router = useRouter();
	const pathname = usePathname();
	const searchParam = useSearchParams();
	const bentoSiteUuid = process.env.NEXT_PUBLIC_BENTO_SITE_UUID;
	const paid = subscription?.is_active ? "paid" : "free";

	React.useEffect(() => {
		const saveCKId = () => {
			const ckSubscriberId = searchParam.get("ck_subscriber_id");
			if (typeof window === "undefined") {
				return false;
			}

			window.localStorage.setItem("ck_subscriber_id", ckSubscriberId);

			router.replace(pathname, { scroll: false });
		};

		if (searchParam.has("ck_subscriber_id")) {
			saveCKId();
		}
	}, [pathname, router, searchParam]);

	// track the users previous and current path
	React.useEffect(() => {
		const storage = window?.sessionStorage;
		if (!storage) return;
		const isAuthPath = ["/login/", "/signup/", "/forgot-password/"].includes(
			storage.getItem("currentPath")
		);
		const pathWithoutParams = window.location.pathname;
		const pathWithParams = window.location.href.replace(process.env.NEXT_PUBLIC_SITE_URL, "");
		const path = pathWithParams.includes("/research-feed/filter")
			? pathWithParams
			: pathWithoutParams;
		// Set the previous path as the value of the current path
		// unless it's login/signup/forgot-pwd then keep existing prevPath
		const prevPath = isAuthPath ? storage.getItem("prevPath") : storage.getItem("currentPath");
		// ignore if same path
		if (window.location.pathname === prevPath) return;
		// save the previous path
		if (prevPath) storage.setItem("prevPath", prevPath);
		// Set the current path value by looking at the browser's location object.
		storage.setItem("currentPath", path);

		let pageviewCount = hasCookie("pageviewCount") ? Number(getCookie("pageviewCount")) : 0;
		if (pageviewCount <= 3) {
			pageviewCount++;
		}
		setCookie("pageviewCount", pageviewCount, { maxAge: 30 * 60 * 60 * 24 });

		let salesPageViewCount = hasCookie("salesPageViewCount")
			? Number(getCookie("salesPageViewCount"))
			: 0;

		if (salesPageViewCount <= 3) {
			salesPageViewCount++;
		}
		setCookie("salesPageViewCount", salesPageViewCount, { maxAge: 30 * 60 * 60 * 24 });
	}, []);

	React.useEffect(() => {
		const shouldSkipSurvey =
			pathname === "/" ||
			pathname.includes("thankyou") ||
			pathname.includes("members") ||
			pathname.includes("checkout") ||
			pathname === "/product-survey/" ||
			pathname === "/login/";

		const shouldShowSurvey =
			user &&
			!user?.survey?.includes("member") &&
			isAfter(parseISO(user?.created_date), parseISO("2022-10-01")) &&
			subscription?.is_active;

		if (!shouldSkipSurvey && shouldShowSurvey) {
			//router.push("/product-survey/");
		}
	}, [pathname, user, subscription]);

	React.useEffect(() => {
		if (user) {
			window.dataLayer = window?.dataLayer || [];
			window.dataLayer?.push({
				event: "set_user_id",
				user_id: user.id,
			});

			const invoiceInterval = subscription?.plan?.invoice_interval;
			const subscriptionType = SUBSCRIPTION_TYPE_MAP[invoiceInterval];

			window.dataLayer?.push({
				"dlv-paid_status": paid,
				"dlv-subscription_type": subscriptionType,
			});
		}
	}, [paid, user, subscription?.plan?.invoice_interval]);

	React.useEffect(() => {
		document.dispatchEvent(new Event("rm:rerun")); // re-run rightmessage on page change
	}, [pathname]);

	React.useEffect(() => {
		const rmHandler = (event: CustomEvent) => {
			const { dimensionName, segmentName } = event.detail;
			sendGTMEvent({
				event: "rm_survey_answer",
				"dlv-rm_dimension": dimensionName,
				"dlv-rm_answer": segmentName,
			});
		};

		window.addEventListener("rm:widget:answer", rmHandler);
		return () => {
			window.removeEventListener("rm:widget:answer", rmHandler);
		};
	}, []);

	React.useEffect(() => {
		const token = getCookie("token");
		if (token) {
			setCookie("token", token, { maxAge: cookieAge });
		}
	}, []);

	return (
		<>
			<Script id="clarity-script">{clarityScript}</Script>
			<Script id="mixpanel-script">{mixpanelScript}</Script>
			{!!bentoSiteUuid && (
				<Script id="bento-script" src={`https://fast.bentonow.com?site_uuid=${bentoSiteUuid}`} />
			)}
			<Script id="rightmessage" strategy="lazyOnload">
				{`
					(function(p, a, n, d, o, b, c) {
						o = n.createElement('script'); o.type = 'text/javascript'; o.async = true; o.src = 'https://tb.rightmessage.com/'+p+'.js';
						b = n.getElementsByTagName('script')[0]; d = function(h, u, i) { var c = n.createElement('style'); c.id = 'rmcloak'+i;
							c.type = 'text/css'; c.appendChild(n.createTextNode('.rmcloak'+h+'{visibility:hidden}.rmcloak'+u+'{display:none}'));
							b.parentNode.insertBefore(c, b); return c; }; c = d('', '-hidden', ''); d('-stay-invisible', '-stay-hidden', '-stay');
						setTimeout(o.onerror = function() { c.parentNode && c.parentNode.removeChild(c); }, a); b.parentNode.insertBefore(o, b);
					})('${process.env.NEXT_PUBLIC_RIGHTMESSAGE_ID}', 20000, document);
				`}
			</Script>
		</>
	);
}
